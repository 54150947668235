/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/assets/css/index.css';
import './src/assets/css/index.scss';
import './src/assets/css/fontawesome-free-5.12.1-web/css/all.min.css';
